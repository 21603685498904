// * {
//   font-family: "Inter", sans-serif;
// }

.MuiAppBar-root.navbar-header {
  // background-color: #201c1c;
  position: fixed;
  z-index: 1001;
}

.MuiToolbar-root.navbar-height {
  justify-content: space-between;
}

.MuiButton-root.delnie-logo {
  margin-right: 26px;
}

.nav-search-bar {
  height: 35px;
  position: relative;
}

.navbar-btn-container {
  gap: 1.5em;
}

.dropdown-grid {
  color: #fff;
}

.MuiButtonBase-root.navbar-btns {
  text-transform: capitalize;
  // color: #ffffff;
  // padding-left: 19px;
  // padding-right: 19px;
}

.MuiButtonBase-root.navbar-btns:hover {
  // background-color: rgba(255, 107, 0, 1);
  color: rgba(255, 107, 0, 1) !important;
}

.MuiButtonBase-root.navbar-mobile-btn {
  padding-right: 0;
}

.menu-navbar .MuiPaper-root.MuiMenu-paper {
  top: 86px !important;
}

.MuiButtonBase-root.navbar-btns h4 {
  font-size: 1.125em;
  font-family: "Oxanium", cursive;
  font-weight: 400;
  margin-bottom: 0;
}

// .MuiButtonBase-root.navbar-btns h4:hover {
//   color: rgba(255, 107, 0, 1);
// }

.MuiButtonBase-root.dealer-btn {
  color: #ffffff;
  border-color: #ffffff;
  border-radius: 0;
  // margin-left: 32px;
  // margin-right: 19px;
}

.MuiButtonBase-root.dealer-btn:hover {
  color: rgba(255, 107, 0, 1);
  border-color: rgba(255, 107, 0, 1);
}

.MuiButtonBase-root.contact-btn {
  background-color: rgba(255, 107, 0, 1);
  color: #ffffff;
  border-radius: 0;
}

.MuiButtonBase-root.contact-btn:hover {
  background-color: #ffffff;
  color: rgba(255, 107, 0, 1);
}

.MuiButtonBase-root.dealer-btn span,
.MuiButtonBase-root.contact-btn span {
  font-size: 1em;
  font-family: "Oxanium", cursive;
  font-weight: 600;
}

.apps-dropdown h6 {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;
  letter-spacing: -0.3px;
  color: #f5f5f5;
  margin-left: 14px;
}

.apps-dropdown h6,
.pro-dropdown-btn h6 {
  margin-bottom: 0;
}

.pro-dropdown-btn h6 {
  font-size: 14px;
  margin-left: 9px;
  text-transform: none;
  color: #fff;
  text-decoration: none;
  border-bottom: none;
}

.pro-dropdown-btn::after {
  content: url("../../assets/images/Navbar/dropdown-arrow.svg");
  margin-left: 5px;
}

.nav-search-bar .nav-search-input .MuiInputBase-input {
  padding-left: 1em;
  font-size: 14px;
}

.nav-search-main .nav-search-bar {
  background-color: rgba($color: #ffffff, $alpha: 0.3);
}

.search-end {
  font-size: 14px;
  position: absolute;
  top: 7px;
  bottom: 0;
  right: 14px;
  opacity: 43%;
}

button.demo-customized-btn {
  margin-top: 13px;
}

.notif-profile-section {
  align-items: center;
  margin-left: 2px;
}

.pro-btn-main {
  margin-left: 2px;
}

.pro-btn-main button span {
  font-weight: 400;
}

.pro-acc-btn {
  position: relative;
}

.pro-acc-btn span {
  align-self: flex-end;
  margin-bottom: 4px;
}

.pro-acc-btn span img {
  align-self: flex-end;
  margin-top: 10px;
}

button.pro-nav-btn {
  text-transform: none;
}

p.para-margin {
  margin-bottom: 0;
}

.span-spacing {
  margin-left: 14px;
}

/*Media queries*/
@media (min-width: 600px) {
  .MuiToolbar-root.navbar-height {
    min-height: 6.25em;
  }
}

@media (max-width: 767px) {
  .nav-search-main {
    width: 25%;
  }
}

@media (max-width: 992px) {
  .MuiButton-root.delnie-logo {
    margin-right: 0px;
  }
  .MuiAppBar-root.navbar-header {
    position: absolute;
  }
}
