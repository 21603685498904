.third-section-container {
  margin-right: 0;
  margin-left: 0;
  background-color: #201c1c;
}

.third-section-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  padding: 5.05% 6.3689% 2.994% 7.61%;
  z-index: 1;
}

.third-section-heading h2 {
  color: #ffffff;
  font-size: 40px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  margin-bottom: 67px;
}

.third-section-features {
  margin-bottom: 104px;
  // max-width: 1318.9px;
  gap: 67px;
}

.third-section-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 332px;
  // margin-right: 4.484%;
}

.third-section-features.third-section-features-second {
  gap: 181px;
}

.third-section-feature.ownership-feature {
  max-width: 363px;
}

.third-section-feature img {
  margin-bottom: 20px;
}

.third-section-feature h4 {
  font-size: 18px;
  font-family: "Oxanium", cursive;
  font-weight: 500;
  color: #ffffff;
}

.third-section-feature h3 {
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 600;
}

.third-section-feature p {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}
