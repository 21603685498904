.product-section-container {
  background-color: #201c1c;
  padding: 200px 110px 80px;
}

.product-header-row h2 {
  color: #ff6b00;
  padding-right: 20px;
  text-align: center;
  font-family: Rubik;
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
}

.product-topic-row {
  padding: 80px 0 50px;
}

.product-topic-row h6 {
  padding-bottom: 25px;
}

.product-category-row {
  gap: 3rem;
  justify-content: space-between;
}

.products-image-col {
  display: flex;
  justify-content: center;
}

.product-image-card {
  text-decoration: none;
  border-radius: 0%;
  background: #000;
}

.product-image-card-body {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  color: #fff;
  text-align: center;
  font-family: Oxanium;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

.no-text-decoration {
  text-decoration: none !important;
}

@media (min-width: 576px) {
  .product-category-grid {
    flex: 0 0 auto;
    width: 18%;
  }
}

@media (max-width: 767px) {
  .product-section-container {
    padding: 120px 20px 40px;
  }

  .product-header-row h2 {
    font-size: 25px;
    line-height: 35px;
  }

  .product-topic-row {
    padding: 50px 0;
  }

  .product-topic-row h6 {
    padding-bottom: 20px;
  }

  .product-category-row {
    padding: 0 0 15px;
    gap: 1rem;
  }

  .products-image-col {
    padding-bottom: 15px;
    width: 100%;
  }

  .product-image-card {
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    margin: 0 auto;
  }

  .headline-image {
    display: none;
  }
}
