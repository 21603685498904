.card-title {
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: Oxanium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 100% */
}

.product_sub_line_row {
  padding-top: 40px;
  padding-bottom: 35px;
}

.product_sub_line_row p {
  color: rgb(174, 173, 173);
  font-family: Oxanium;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product_sub_line_row p span {
  color: rgb(255, 255, 255);
  font-family: Oxanium;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.no-text-decoration {
  text-decoration: none !important;
  color: rgb(255, 255, 255);
}

.product-image-card {
  align-items: center;
}

// .product-item-image {
//   width: 70%;
//   height: 83%;
// }
