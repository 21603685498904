.footer-container {
  margin-right: 0;
  margin-left: 0;
  // background-color: #201c1c;
}

.footer-main {
  padding-left: 0;
  padding-right: 0;
  padding: 3.147% 10.39% 2.56% 7.247%;
}

.footer-content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-content-container-mobile,
.footer-links-container-mobile {
  display: none;
}

.footer-pages-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.footer-pages-section h2 {
  font-size: 25px;
  font-family: "Oxanium", cursive;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 15px;
}

.footer-pages-section .MuiButtonBase-root {
  padding-left: 0;
}

.footer-pages-section .MuiButtonBase-root p {
  font-size: 16px;
  font-family: "Oxanium", cursive;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
}

.footer-pages-section .MuiButtonBase-root a {
  font-size: 16px;
  font-family: "Oxanium", cursive;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
}

.footer-links-section h2 {
  font-size: 25px;
  font-family: "Oxanium", cursive;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 28px;
}

.footer-links-section p {
  font-size: 16px;
  font-family: "Oxanium", cursive;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0;
}

.footer-links-section a {
  font-size: 16px;
  font-family: "Oxanium", cursive;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0;
}

// .footer-links-container {
//   padding-left: 16px;
// }

.footer-link {
  margin-bottom: 25px;
}

.footer-link .MuiButtonBase-root {
  padding-left: 0;
}

.footer-link .MuiButtonBase-root p {
  font-size: 16px;
  font-family: "Oxanium", cursive;
  font-weight: 600;
  text-transform: lowercase;
}

.footer-link .MuiButtonBase-root a {
  font-size: 16px;
  font-family: "Oxanium", cursive;
  font-weight: 600;
  text-transform: lowercase;
  text-decoration: none;
}

.footer-link .MuiButtonBase-root p span {
  text-transform: capitalize;
}

.footer-link img {
  margin-right: 10px;
}

.footer-pages-section .MuiButtonBase-root p:hover,
.footer-link .MuiButtonBase-root p:hover {
  color: #ff6b00;
}

.footer-social-links .MuiButtonBase-root {
  width: 20px;
  min-width: 24px;
}

.footer-social-links .MuiButtonBase-root:hover {
  background-color: #ff6b00;
}

@media (max-width: 767px) {
  .footer-content-container {
    display: none;
  }

  .footer-content-container-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-links-container-mobile {
    display: block;
  }

  .footer-links-section h2 {
    font-size: 16px;
  }

  .footer-link .MuiButtonBase-root p {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-link .MuiButtonBase-root a {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-acceron-logo {
    margin-bottom: 52px;
  }

  .footer-main {
    padding-top: 7.147%;
  }

  .footer-pages-section {
    margin-bottom: 30px;
  }
}
