.contact-first-section-container {
  padding-top: 200px;
  padding-bottom: 100px;
  padding-left: 110px;
  padding-right: 110px;
  background-color: #201c1c;
}
.contact-first-section-row {
  padding-bottom: 40px;
}

.contact-head-and-para h6 {
  color: #fff;

  font-family: Oxanium;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 60% */
}

.contact-head-and-para h6 span {
  color: #ff6b00;
}

.contact-head-and-para p {
  padding-top: 20px;
  color: #e6e6e6;

  font-family: Oxanium;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 156.522% */
}

.head-and-para h6 {
  color: #fff;
  text-align: center;
  font-family: Oxanium;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 60% */
}

.contact-head-and-para h6 span {
  color: #ff6b00;
}

.head-and-para p {
  padding-top: 20px;
  color: #e6e6e6;
  text-align: center;
  font-family: Oxanium;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 156.522% */
}

.contact-box label {
  color: #fff;
  font-family: Oxanium;
  opacity: 0.6;
  //   font-size: 23px;
  font-style: normal;
  font-weight: 400;
  //   line-height: 23px; /* 100% */
}

.contact-second-section-row {
  padding-bottom: 32px;
}

.send-btn-row {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.send-btn-row .MuiButtonBase-root {
  background-color: rgba(255, 107, 0, 1);
  color: #ffffff;
  width: 154px;
  height: 45px;
  font-size: 12px;
  border-radius: 0;
}

.contact-link-row {
  padding-bottom: 5px;
}

.contact-link-row h2 {
  padding-left: 20px;
  color: #ffffff;
  font-family: Oxanium;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 100% */
}

@media (max-width: 767px) {
  .contact-first-section-container {
    padding-top: 120px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background: #201c1c;
  }
  .contact-first-section-row {
    padding-bottom: 20px;
  }

  .head-and-para h6 {
    color: #fff;
    text-align: center;
    font-family: Oxanium;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 60% */
  }

  .head-and-para h6 span {
    color: #ff6b00;
  }

  .head-and-para p {
    padding-top: 0px;
    color: #e6e6e6;
    text-align: center;
    font-family: Oxanium;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 156.522% */
  }

  .contact-head-and-para h6 {
    color: #fff;
    text-align: center;
    font-family: Oxanium;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 60% */
  }

  .contact-head-and-para h6 span {
    color: #ff6b00;
  }

  .contact-head-and-para p {
    padding-top: 0px;
    color: #e6e6e6;
    text-align: center;
    font-family: Oxanium;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 156.522% */
  }

  .contact-box label {
    color: #fff;
    font-family: Oxanium;
    opacity: 0.6;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 100% */
  }

  .contact-second-section-row {
    padding-bottom: 0px;
  }

  .send-btn-row {
    padding-top: 40px;
    display: flex;
    justify-content: center;
  }

  .send-btn-row .MuiButtonBase-root {
    background-color: rgba(255, 107, 0, 1);
    color: #ffffff;
    width: 154px;
    height: 45px;
    font-size: 12px;
    border-radius: 0;
  }

  .contact-link-row {
    padding-bottom: 5px;
  }

  .contact-link-row h2 {
    padding-left: 20px;
    color: #ffffff;
    font-family: Oxanium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 100% */
  }

  .contact-field-col {
    padding-left: 0%;
  }
}
