.product-item-name-row {
  padding-bottom: 20px;
}

.product-item-name-row h1 {
  color: #fff;
  font-family: Rubik;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 49px; /* 122.5% */
}

.product-item-name-row h1 span {
  color: #ff6b00;
}

.Product-details-image {
  // width: 225px;
  height: auto;
  flex-shrink: 0;
}

.product-details-image-card {
  border: 1px solid #fff;
  background-color: #201c1c;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .tab-buttons {
// }

.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.tab-button {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  color: #fff;
  font-family: Oxanium;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  border: 0ch;
  text-transform: capitalize;
  background-color: #201c1c;
  line-height: 30px;
}

.product-details-tab .MuiTab-root {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.tab-button.active {
  border-bottom: 2px solid #ff6b00;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  letter-spacing: 0.1px;
}

.no-text-decoration {
  text-decoration: none !important;
}
