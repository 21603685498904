.fourth-section-container {
  margin-right: 0;
  margin-left: 0;
  background-color: #201c1c;
}

.fourth-section-main {
  padding-left: 0;
  padding-right: 0;
  padding: 2.994% 0 8.27% 4.97%;
}

.fourth-section-heading {
  margin-bottom: 3.92%;
  // overflow-x: hidden;
}

.fourth-section-heading h2 {
  margin-right: 19px;
  font-size: 24px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0;
}

.fourth-section-features {
  width: 186px;
  margin-bottom: 40px;
}

.fourth-section-features h2 {
  font-size: 28px;
  font-family: "Oxanium", cursive;
  color: rgba($color: #ffffff, $alpha: 0.7);
}

.fourth-section-features .MuiButtonBase-root {
  padding-left: 0;
  color: #ffffff;
  padding-bottom: 10px;
  padding-top: 12px;
}

.fourth-section-features .MuiButtonBase-root:hover {
  color: #ff6b00;
}

.fourth-section-features .MuiButtonBase-root h4 {
  font-size: 18px;
  font-family: "Oxanium", sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 0;
}

.MuiPaper-root.fourth-section-card {
  border: 2px solid #ffffff;
  background-color: #201c1c;
  width: 100%;
  height: 100%;
  // max-height: 466px;
}

.MuiPaper-root.fourth-section-card .MuiButtonBase-root {
  padding-left: 0;
}

.MuiPaper-root.fourth-section-card .MuiButtonBase-root span {
  margin-right: 11px;
}

.MuiPaper-root.fourth-section-card .MuiCardContent-root {
  padding: 0;
}

.MuiPaper-root.fourth-section-card h4,
.MuiPaper-root.fourth-section-card p {
  color: #ffffff;
}

.MuiPaper-root.fourth-section-card h4 {
  font-size: 18px;
  font-family: "Oxanium", cursive;
  font-weight: 600;
}

.MuiPaper-root.fourth-section-card p {
  font-size: 13px;
  font-family: "Oxanium", cursive;
}

.fourth-section-carousel-card {
  width: 100%;
  height: 100%;
}

.MuiDivider-root.fourth-section-divider {
  background-color: #ffffff;
  width: 186px;
}

@media (max-width: 767px) {
  .MuiPaper-root.fourth-section-card h4 {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .MuiPaper-root.fourth-section-card p {
    font-size: 12px;
  }

  .fourth-section-features-container {
    flex-direction: column;
    overflow: hidden;
  }
  .MuiPaper-root.fourth-section-card {
    border: 2px solid #ffffff;
    background-color: #000000;
    width: 222px;
    height: 391px;
  }

  .fourth-section-main {
    padding: 4.994% 7% 8.27% 4.97%;
  }

  .fourth-section-heading {
    white-space: nowrap;
  }

  .fourth-section-heading {
    margin-bottom: 8.226%;
  }

  .fourth-section-features {
    width: 123px;
  }

  .fourth-section-features .MuiButtonBase-root h4 {
    font-size: 12px;
    white-space: nowrap;
  }

  .MuiDivider-root.fourth-section-divider {
    width: 123px;
  }
}
