.first-section-container {
  margin-right: 0;
  margin-left: 0;
}

.outer-video {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.outer-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.outer-video .first-section-text {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  color: #ffffff;
  transform: translate(0%, 0%);
  padding: 7.2% 8.05% 9.6% 8.05%;
  margin-top: 100px;
}

// .video-container {
//   opacity: 0;
//   transition: opacity 2s ease-in-out;
// }

// .fade-in {
//   opacity: 1;
// }

.first-image-main {
  position: relative;
  width: 100%;
  height: auto;
  padding-left: 0;
  padding-right: 0;
}

.first-section-text h2 {
  font-size: 53px;
  font-family: "Oxanium", cursive;
  font-weight: 600;
  color: #ffffff;
  max-width: 1059px;
  margin-bottom: 20px;
}

.first-section-text p {
  font-size: 23px;
  font-family: "Oxanium", cursive;
  font-weight: 500;
  color: #ffffff;
  max-width: 1146px;
  margin-bottom: 33px;
}

.first-section-text .MuiButtonBase-root {
  background-color: rgba(255, 107, 0, 1);
  color: #ffffff;
  width: 154px;
  height: 45px;
  border-radius: 0;
}

.first-section-text .MuiButtonBase-root span {
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 600;
}

.first-section-text .MuiButtonBase-root:hover {
  background-color: #ffffff;
  color: rgba(255, 107, 0, 1);
}

.no-text-decoration {
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .first-image-main {
    display: flex;
    align-items: center;
  }

  .first-section-text {
    text-align: center;
  }
  .first-section-text h2 {
    position: static;
    font-size: 21px;
  }

  .first-section-text p {
    font-size: 16px;
  }

  .first-section-text .emotion-text {
    position: static;
    font-size: 70px;
  }

  .outer-video .first-section-text {
    text-align: left;
    padding: 18.28% 5.128% 16.1538% 5.128%;
    margin-top: 56px;
  }

  .first-section-text h2 {
    margin-bottom: 13px;
  }

  .first-section-text p {
    margin-bottom: 16px;
  }

  .first-section-text .MuiButtonBase-root {
    width: 106px;
    height: 28px;
  }

  .first-section-text .MuiButtonBase-root span {
    font-size: 12px;
    white-space: nowrap;
  }

  .no-text-decoration {
    text-decoration: none !important;
  }
}
