.left-drawer .MuiPaper-root.MuiDrawer-paper {
  width: 100%;
}

.sidebar-btn-container {
  padding-top: 20px;
  padding-right: 27px;
  padding-left: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sidebar-btn-container .MuiButtonBase-root span {
  font-size: 12px;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 600;
}

.MuiButtonBase-root.dealer-sidebar-btn {
  border-color: #000000;
  border-radius: 0;
}

.MuiButtonBase-root.dealer-sidebar-btn span {
  color: #201c1c;
}

.MuiListItemText-root span {
  font-size: 18px;
  font-family: "Kumbh Sans", sans-serif;
}

.MuiListItemText-root.orange-text span {
  font-size: 18px;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 600;
}

.MuiListItemText-root.sidebar-contact-link span {
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 600;
}
