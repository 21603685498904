// .inputSelect {
// }

.dealer-first-section-container {
  padding-top: 200px;
  padding-left: 110px;
  padding-right: 110px;
  padding-bottom: 100px;
  background: #201c1c;
}
// .dealer-first-section-row {
//   padding-left: 110px;
//   padding-right: 110px;
// }

.dealer-first-section-row h2 {
  color: #fff;
  font-family: Rubik;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 100% */
}

.dealer-first-section-row h2 span {
  color: #ff6b00;
}
.find-dealer-filter-row {
  border-top: 1px solid #fff;
}
.find-dealer-col {
  padding-left: 45px;

  background: #d9d9d9;
  padding-right: 40px;
}

.find-dealer-detail-col {
  padding-left: 45px;
  padding-top: 40px;
  padding-right: 110px;
}
.find-dealer-detail-row {
  padding-bottom: 80px;
}

.search-location-row {
  padding-top: 50px;

  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

.search-location {
  color: rgb(0, 0, 0);
  font-family: Oxanium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
}

.search-col .search-bar {
  border: 1px solid #fff;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px; /* 100% */

  display: flex;
  width: 395px;
  height: 35px;
  padding: 9px 320px 10px 24px;
  align-items: center;
  flex-shrink: 0;
}

.MuiButtonBase-root.filter-cat-pro {
  color: #ffffff;
  border: 1px solid #fff;
}

.MuiButtonBase-root.filter-cat-pro span {
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.sort-col {
  padding-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.view h1 {
  color: #fff;
  font-family: Oxanium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
}

.filter-row {
  padding-top: 100px;

  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

.filter {
  color: rgb(0, 0, 0);
  font-family: Oxanium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
}

.dealer-details-row {
  padding-top: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.dealer-details-btn-col {
  display: flex;
  justify-content: center;
}
.dealer-name {
  color: #ff6b00;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
}
.dealer-address {
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.dealer-mail {
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.view-on-map-btn text {
  display: inline-flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #fff;
  text-align: center;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
}

.dealer-details-btn-col .MuiButtonBase-root {
  background-color: rgba(255, 107, 0, 1);
  color: #ffffff;
  width: 154px;
  height: 45px;
  border-radius: 0;
}

.view {
  // align-items: center;
  padding-top: 12px;
}

.sort-col {
  align-items: center;
}

.search-location-img {
  float: right;
}

.custom-dropdown .MuiSelect-icon {
  color: white; /* Change the color to white */
  fill: #ffff;
}

// .contact-field-col {
// }
.dropdown-select {
  border: 1px solid #fff;
}
// .input-select-label {
//   color: #fff !important;
//   background-color: #fff;
// }

// /* Your CSS file or inline style */
// .select-root {
//   border: 1px solid white; /* Set the border color to white */
//   border-radius: 4px; /* You can add border-radius for rounded corners, if desired */
//   outline: none; /* Remove the blue outline when the Select is focused */
//   color: white; /* Set the text color to white for the selected value */

// }
// /* Style the placeholder text */
// .select-root::placeholder {
//   color: white; /* Set the placeholder text color to white */
// }
// /* Remove the blue color on option hover and selection */
// .select-root:hover,
// .select-root:focus,
// .select-root:active,
// .select-root::selection {
//   background-color: transparent; /* Set the background-color to transparent */
//   color: white; /* Set the text color to white */
// }

// /* Remove the blue color on the dropdown menu options */

// .MuiListItem-root:hover,
// .Mui-selected {
//   background-color: transparent !important; /* Set the background-color to transparent */
//   color: white !important; /* Set the text color to white */
// }
/* Your CSS file or inline style */
.select-root {
  // border: 1px solid white; /* Set the border color to white */
  border-radius: 4px; /* You can add border-radius for rounded corners, if desired */
  outline: none; /* Remove the blue outline when the Select is focused */
  color: white !important; /* Set the text color to white for the selected value */
}
// .MuiOutlinedInput-notchedOutline{
//   border-color: white !important;
// }

/* Style the placeholder text */
.select-root::placeholder {
  color: white; /* Set the placeholder text color to white */
}

/* Style the selected value */
// .Mui-selected {
//   background-color: transparent !important; /* Set the background-color to transparent */
//   color: white !important; /* Set the text color to white */
// }

/* Style the dropdown menu options */
.MuiListItem-root {
  color: black !important; /* Set the text color for unselected options to black */
}

/* Remove the blue color on option hover */
.select-root:hover,
.select-root:focus,
.select-root:active,
.select-root::selection {
  background-color: transparent; /* Set the background-color to transparent */
  color: white; /* Set the text color to white */
}

@media (max-width: 767px) {
  .dealer-first-section-container {
    padding-top: 120px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    background: #201c1c;
  }
  // .dealer-first-section-row {
  //   padding-left: 20px;
  //   padding-right: 20px;
  // }

  .dealer-first-section-row h2 {
    color: #fff;
    font-family: Rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 100% */
  }

  .dealer-first-section-row h2 span {
    color: #ff6b00;
  }

  .find-dealer-filter-row {
    border-top: 1px solid #fff;
  }
  .find-dealer-col {
    display: none;
    padding-left: 45px;

    background: #d9d9d9;
    padding-right: 40px;
  }

  .find-dealer-detail-col {
    padding-left: 20px;
    padding-right: 20px;
  }
  .find-dealer-detail-row {
    padding-bottom: 40px;
  }

  .search-location-row {
    padding-top: 50px;

    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  }

  .search-location {
    color: rgb(0, 0, 0);
    font-family: Oxanium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
  }

  .search-col .search-bar {
    border: 1px solid #fff;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px; /* 100% */

    display: flex;
    width: 100%;
    padding: 9px 320px 10px 24px;
    align-items: center;
    flex-shrink: 0;
  }

  .MuiButtonBase-root.filter-cat-pro {
    color: #ffffff;
    border: 1px solid #fff;
  }

  .MuiButtonBase-root.filter-cat-pro span {
    color: #fff;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }

  .sort-col {
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .view h1 {
    color: #fff;
    font-family: Oxanium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
  }

  .filter-row {
    padding-top: 100px;

    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  }

  .filter {
    color: rgb(0, 0, 0);
    font-family: Oxanium;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
  }

  .dealer-details-row {
    padding-top: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  }

  .dealer-details-btn-col {
    display: flex;
    justify-content: center;
  }
  .dealer-name {
    color: #ff6b00;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
  }
  .dealer-address {
    color: #fff;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  .dealer-mail {
    color: #fff;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  .view-on-map-btn text {
    display: inline-flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #fff;
    text-align: center;
    font-family: Oxanium;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
  }

  .dealer-details-btn-col .MuiButtonBase-root {
    background-color: rgba(255, 107, 0, 1);
    color: #ffffff;
    width: 100px;
    height: 28px;
    font-size: 8px;
    border-radius: 0;
  }

  .view {
    // align-items: center;
    padding-top: 12px;
  }

  .sort-col {
    align-items: center;
  }

  .search-location-img {
    float: right;
  }

  .search-col {
    padding-bottom: 15px;
  }

  .contact-dropdown-col {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
