.about-first-section-container {
  padding-top: 200px;
  padding-right: 110px;
  padding-left: 110px;
  padding-bottom: 60px;
  background: #201c1c;
  width: 100%;
  overflow: hidden;
}

.acceron-bg {
  position: absolute;
  transform: translate(0%, 20%);
}

.fourth-section-heading.aboutus-first-heading {
  margin-bottom: 5.846%;
}

.fourth-section-heading.aboutus-first-heading h2 {
  font-size: 40px;
}

// .about-first-section-main {
// }

.about-second-section-text {
  padding-right: 50px;
  color: #fff;
  font-family: Oxanium;
  font-size: 40px;
  font-weight: 600;
  line-height: 61px;
}

.about-second-section-text h4 {
  color: #fff;
  font-family: Oxanium;
  font-size: 40px;
  font-weight: 600;
  line-height: 61px;
}

.about-second-section-text h4 span {
  color: #ff6b00;
}

.about-second-section-description {
  padding-left: 100px;
  color: #fff;
  font-family: Oxanium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.about-second-section-description p {
  color: #fff;
  font-family: Oxanium;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
}

.about-third-section-description {
  padding-top: 8.565%;
}

.about-third-section-description h5 {
  padding-bottom: 42px;
  color: #fff;
  font-family: Oxanium;
  font-size: 40px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 100% */
}

.about-third-section-description p {
  text-align: center;
  color: #e6e6e6;
  font-family: Oxanium;
  font-size: 20px;
  line-height: 36px;
}

.about-third-section-description p span {
  color: #ff6b00;
  font-weight: bold;
}

.fourth-section-heading h2 span {
  color: #ff6b00;
}

@media (max-width: 767px) {
  .about-first-section-container {
    padding-top: 120px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 30px;
    background: #201c1c;
    width: 100%;
    overflow: hidden;
  }

  .acceron-bg {
    position: absolute;
    transform: translate(0%, 150%);
  }

  .fourth-section-heading.aboutus-first-heading {
    margin-bottom: 5.846%;
  }
  .fourth-section-heading.aboutus-first-heading h2 {
    font-size: 30px;
  }

  // .about-first-section-main {
  // }

  .about-second-section-text h4 span {
    color: #ff6b00;
  }

  .about-second-section-text h4 {
    color: #fff;
    font-family: Oxanium;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 145% */
  }

  .about-second-section-text h4 span {
    color: #ff6b00;
  }
  .about-second-section-description {
    padding-left: 0px;
  }

  .about-second-section-description p {
    color: #fff;
    font-family: Oxanium;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .about-second-section-description p {
    color: #fff;
    font-family: Oxanium;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
  }

  .about-third-section-description {
    padding-top: 8.565%;
  }

  .about-third-section-description h5 {
    padding-bottom: 15px;
    color: #fff;
    font-family: Oxanium;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
  }

  .about-third-section-description p {
    color: #ffffff;
    text-align: center;
    font-family: Oxanium;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 162.5% */
  }

  .about-third-section-description p span {
    color: #ff6b00;
    font-weight: bold;
  }

  .fourth-section-heading h2 span {
    color: #ff6b00;
  }
}
