.contact-information-container {
  padding-top: 20px;
  padding-bottom: 100px;
  padding-left: 110px;
  padding-right: 110px;
  background: #201c1c;
}

.contact-information-row-one {
  padding-bottom: 40px;
}

.contact-information-row-col {
  padding-bottom: 50px;
}

.contact-information-row-two {
  padding-bottom: 45px;
}

.contact-information-row-two h6 {
  color: #fff;
  font-family: Oxanium;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
}

.contact-information-row-two h6 span {
  color: #ff6b00;
}

.contact-information-row-two p {
  color: #e6e6e6;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 156.522% */
}

@media (max-width: 767px) {
  .contact-information-container {
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background: #201c1c;
  }

  .contact-information-row-one {
    padding-bottom: 20px;
  }

  .contact-information-row-two {
    padding-bottom: 20px;
  }

  .contact-information-row-two h6 {
    color: #fff;
    font-family: Oxanium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 166.667% */
  }

  .contact-information-row-two h6 span {
    color: #ff6b00;
  }

  .contact-information-row-two p {
    color: #e6e6e6;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 156.522% */
  }
}
