.contact-information-container.about-information-container {
  padding: 3.95% 6.95% 8.565% 7.393%;
}

.ldship-card {
  border: 2px solid #fff;
}
.ldship-img-row {
  padding-top: 45px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
.ldship-image {
  border-radius: 50%;
  width: 202.4px;
  height: 177px;
  flex-shrink: 0;
}

.ldship-name-row {
  padding-top: 40px;
}

.ldship-name-row h1 {
  color: #fff;
  font-family: Kumbh Sans;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ff6b00;
}

.ldship-position-row {
  padding-top: 10px;
  padding-bottom: 45px;
}

.ldship-position-row h2 {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: Kumbh Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 151.523%; /* 24.244px */
}

.ldship-position-row h2 span {
  color: #201c1c;
}

.gallery-row-one {
  padding-top: 100px;
  padding-bottom: 35px;
}
.gallery-row-one .gallery-view-all-btn {
  display: flex;
}

.about-heads-container {
  margin-bottom: 107px;
}

.fourth-section-heading.aboutus-gallery-heading {
  margin-bottom: 0;
}

.about-gallery-container {
  gap: 32px;
}

.about-gallery-container .MuiPaper-root {
  border-radius: 0;
}

.aboutus-gallery-main {
  margin-bottom: 3.92%;
}

.MuiButtonBase-root.about-viewall-btn {
  color: #ffffff;
  border-color: #ffffff;
  text-transform: capitalize;
  border-radius: 0;
  width: 142px;
}

.MuiButtonBase-root.about-viewall-btn:hover {
  color: #ff6b00;
  border-color: #ff6b00;
}

@media (max-width: 767px) {
  .contact-information-container.about-information-container {
    padding: 3.95% 6.95% 8.565% 7.393%;
  }

  .ldship-card {
    border: 2px solid #fff;
  }
  .ldship-img-row {
    padding-top: 45px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  .ldship-image {
    border-radius: 50%;
    width: 202.4px;
    height: 177px;
    flex-shrink: 0;
  }

  .ldship-name-row {
    padding-top: 40px;
  }

  .ldship-name-row h1 {
    color: #fff;
    font-family: Kumbh Sans;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #ff6b00;
  }

  .ldship-position-row {
    padding-top: 10px;
    padding-bottom: 45px;
  }

  .ldship-position-row h2 {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Kumbh Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.523%; /* 24.244px */
  }

  .ldship-position-row h2 span {
    color: #201c1c;
  }

  .gallery-row-one {
    padding-top: 100px;
    padding-bottom: 35px;
  }
  .gallery-row-one .gallery-view-all-btn {
    display: flex;
  }

  .about-heads-container {
    margin-bottom: 107px;
  }

  .fourth-section-heading.aboutus-gallery-heading {
    margin-bottom: 0;
  }

  .about-gallery-container {
    gap: 32px;
  }

  .about-gallery-container .MuiPaper-root {
    border-radius: 0;
  }

  .aboutus-gallery-main {
    margin-bottom: 3.92%;
  }

  .MuiButtonBase-root.about-viewall-btn {
    color: #ffffff;
    border-color: #ffffff;
    text-transform: capitalize;
    border-radius: 0;
    width: 142px;
  }

  .MuiButtonBase-root.about-viewall-btn:hover {
    color: #ff6b00;
    border-color: #ff6b00;
  }
}
