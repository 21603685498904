.gallery-folder-container {
  padding-top: 200px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
  background: #201c1c;
}

.gallery-folder-first-row {
  padding-bottom: 40px;
}

.gallery-folder-first-row h2 {
  padding-right: 20px;
  color: #ff6b00;
  font-family: Rubik;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 100% */
}

.gallery-folder-second-row-col {
  display: flex;
  justify-content: center;
}

.gallery-card {
  border-radius: 0%;
  border: 2px solid #fff;
  background: #201c1c;
  //   width: 361px;
  //   height: 343px;
  flex-shrink: 0;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.gallery-image-card {
}

.gallery-image-card-body {
}

.gallery-image-card-title {
}
