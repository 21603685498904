.image-item {
  padding: 10px 0 10px 14px;
  // width: 400px !important;
}

.MuiPaper-root .MuiCardContent-root .fourth-section-card-text {
  padding: 4.40625% 2.3%;
}

.MuiPaper-root
  .MuiCardContent-root
  .fourth-section-card-text
  .MuiButtonBase-root
  span {
  text-transform: capitalize;
  color: #ff6b00;
}

@media (max-width: 767px) {
  li.react-multi-carousel-item.image-item {
    width: auto !important;
  }

  .fourth-section-carousel-card {
    width: 100%;
  }

  // .MuiPaper-root .MuiCardContent-root .fourth-section-card-text {
  //   padding: 14px 10px 18px 10px;
  // }

  .MuiPaper-root
    .MuiCardContent-root
    .fourth-section-card-text
    .MuiButtonBase-root
    span {
    font-size: 12px;
  }
}
