.career-first-section-container {
  padding-top: 200px;
  padding-bottom: 100px;
  padding-left: 110px;
  padding-right: 110px;
  background: #201c1c;
}

.careers-infornation-row {
  padding-bottom: 100px;
}

.careers-infornation-text {
  padding-right: 120px;
}

.careers-infornation-text p {
  color: #e6e6e6;
  font-family: Oxanium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}

.career-box label {
  color: #fff;
  opacity: 0.6;
  font-family: Oxanium;
  //   font-size: 23px;
  font-style: normal;
  font-weight: 400;
  //   line-height: 23px; /* 100% */
}

// .career-box {
//     border: 1px solid #fff;
// }

.career-resume-box label {
  color: #ff6b00;
  font-family: Oxanium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 115% */
}

.submit-btn-row {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.submit-btn-row .MuiButtonBase-root {
  background-color: rgba(255, 107, 0, 1);
  color: #ffffff;
  width: 182px;
  height: 45px;
  font-size: 12px;
  border-radius: 0;
}

.MuiPaper-root.resume-upload {
  // border: 1px solid #ffffff;
  background-color: #201c1c;
}

.resume-upload-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 767px) {
  .career-first-section-container {
    padding-top: 120px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    background: #201c1c;
  }

  .careers-infornation-row {
    padding-bottom: 50px;
  }
  .careers-infornation-image-row {
    padding-bottom: 20px;
  }

  .careers-infornation-text {
    padding-right: 0px;
  }

  .careers-infornation-text p {
    color: #e6e6e6;
    font-family: Oxanium;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
  }

  .career-box label {
    color: #fff;
    opacity: 0.6;
    font-family: Oxanium;
    //   font-size: 23px;
    font-style: normal;
    font-weight: 400;
    //   line-height: 23px; /* 100% */
  }

  // .career-box {
  //     border: 1px solid #fff;
  // }

  .career-resume-box label {
    color: #ff6b00;
    font-family: Oxanium;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 115% */
  }

  .submit-btn-row {
    padding-top: 40px;
    display: flex;
    justify-content: center;
  }

  .submit-btn-row .MuiButtonBase-root {
    background-color: rgba(255, 107, 0, 1);
    color: #ffffff;
    width: 182px;
    height: 45px;
    font-size: 12px;
    border-radius: 0;
  }

  .MuiPaper-root.resume-upload {
    // border: 1px solid #ffffff;
    background-color: #201c1c;
  }

  .resume-upload-col {
    display: flex;
    justify-content: flex-end;
  }
}
