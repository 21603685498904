.gallery-details-container {
  margin-left: 0;
  margin-right: 0;
}

.gallery-details-main {
  width: 100%;
  //   height: 100vh;
  padding: 7.077% 7.17% 23.06% 7.17%;
  margin-top: 100px;
}

.gallery-details-images {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.gallery-details-images div:last-child:nth-last-child(2),
.gallery-details-images div:last-child:nth-last-child(2) ~ div {
  margin-right: auto;
  margin-left: auto;
}

.gallery-details-image-main {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.gallery-details-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0 0 0 / 0.8);
  transition: 0.2s;
}

.gallery-details-modal .btn-cross,
.gallery-details-modal .btn-prev,
.gallery-details-modal .btn-next {
  position: fixed;
  color: #fff;
  opacity: 0.6;
  z-index: 9999;
  cursor: pointer;
  transition: 0.2s;
}

.btn-cross,
.btn-prev,
.btn-next {
  opacity: 1;
}

.gallery-details-modal .btn-cross {
  top: 100px;
  right: 40px;
}

.gallery-details-modal .btn-prev {
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}

.gallery-details-modal .btn-next {
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.gallery-details-fullscreen {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-details-fullscreen img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  user-select: none;
}

.gallery-details-image-main:hover {
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .gallery-details-image-main {
    justify-content: center;
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 1280px) {
  .gallery-details-main {
    min-height: 100vh;
  }
}
