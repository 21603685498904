.product-tetails-tab-row {
  padding-top: 30px;
}

.product-tetails-tab-row h1 {
  padding-top: 30px;
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.product-tetails-tab-row h2 {
  padding-top: 30px;
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.product-tetails-tab-row p {
  padding-top: 40px;
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
}

.product-tetails-tab-row h3 {
  padding-top: 40px;
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
}

.product-tetails-tab-row h3 span {
  //   padding-left: 20px;
  //   padding-right: 20px;
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 175% */

  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  //   justify-content: space-between;
  align-items: center;
  gap: 20px;
  border: 1px solid #fff;
}

// .product-tetails-tab-second-row {
//     padding-top: 30px;
// }

.product-tetails-tab-second-row h1 {
  padding-top: 30px;
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
}

.product-tetails-tab-second-row h1 span {
  color: #fff;
  font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
