.second-section-container {
  background-color: #201c1c;
  margin-right: 0;
  margin-left: 0;
  position: relative;
}

.orange-container {
  position: absolute;
  background-color: #ff6b00;
  width: 100%;
  height: 423px;
  bottom: 0;
}

.second-section-desc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.second-section-main {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8.64% 6.44% 5.05% 8%;
}

.second-section-text {
  max-width: 1314px;
  text-align: center;
  margin-bottom: 2.535%;
}

.second-section-text h4 {
  font-size: 32px;
  font-family: "Oxanium", cursive;
  color: #ffffff;
}

.second-section-text h4 span {
  color: #ff6b00;
}

.divider-line {
  margin-bottom: 3.379%;
}

.second-section-features {
  margin-bottom: 3.2414%;
  z-index: 1;
}

.second-section-features img {
  transition: transform 0.5s;
}

.second-section-features img:hover {
  transform: scale(1.2);
}

.second-section-description {
  max-width: 71%;
  margin-bottom: 1.811%;
  z-index: 1;
}

.second-section-description p {
  font-size: 20px;
  font-family: "Oxanium", sans-serif;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 0;
}

.MuiButtonBase-root.explore-product-btn {
  color: #ffffff;
  border-color: #ffffff;
}

.MuiButtonBase-root.explore-product-btn:hover {
  color: #201c1c;
  border-color: #201c1c;
}

.MuiButtonBase-root.explore-product-btn span {
  font-size: 16px;
  font-family: "Oxanium", sans-serif;
  font-weight: 600;
}

.no-text-decoration {
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .second-section-main {
    padding: 8.64% 6.44% 12.298% 8%;
  }

  .second-section-desc-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
  }

  .second-section-text h4 {
    font-size: 22px;
  }

  .second-section-description p {
    font-size: 19px;
  }

  .MuiButtonBase-root.explore-product-btn span {
    font-size: 12px;
  }

  .second-section-features img {
    margin-bottom: 8.984%;
    width: 28%;
  }

  .second-section-text {
    margin-bottom: 6.535%;
  }

  .divider-line {
    margin-bottom: 11.379%;
  }

  .second-section-desc-container {
    text-align: start;
    z-index: 1;
  }

  .second-section-description {
    margin-bottom: 5.99%;
  }
}

@media (max-width: 1280px) {
  .second-section-features img {
    margin-bottom: 8.984%;
    width: 28%;
  }

  .no-text-decoration {
    text-decoration: none !important;
  }
}
